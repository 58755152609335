'use client'

import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { useAccount, useDisconnect } from 'wagmi'
import { shortenAddress, toLocaleString } from 'helpers'
import Image from 'next/image'
import userImage from 'public/images/user.png'
import { Message } from '@locmod/intl'
import { Icon } from 'components/ui'
import messages from './messages'
import { useProfileMe, useUserEns } from 'hooks'
import { useAuthContext } from 'contexts'
import { ButtonBase } from 'components/inputs'
import { PopoverButton, PopoverPanel, Popover } from '@headlessui/react'
import { Href } from 'components/navigation'
import clsx from 'clsx'

const Balance = () => {
  const { address } = useAuthContext()
  const { data, isFetching } = useProfileMe()

  if (!address) {
    return null
  }

  return (
    <div className="mr-6 text-right leading-none mb:hidden">
      <Message className="text-px-12 text-white" value={messages.balance} />
      <div className="flex items-center justify-end">
        <Icon className="mr-1 size-6 flex-none" name="ui/coin" />
        <span className="text-px-16 font-bold text-white">
          {isFetching ? (
            <Icon className="size-4" name="ui/loading" />
          ) : (
            toLocaleString(data?.balance || 0, { digits: 0 })
          )}
        </span>
      </div>
    </div>
  )
}

const baseItemClassName = 'flex h-11 items-center gap-1 text-left text-px-12 font-bold uppercase leading-150p'

const ProfileButton: React.FC = () => {
  const { address } = useAuthContext()
  const { disconnect, isPending } = useDisconnect()
  const { name, avatar } = useUserEns()

  return (
    <ConnectButtonWrapper connectViewProps>
      <div className="inline-flex items-center">
        <Balance />
        <Popover>
          <PopoverButton
            className="group relative flex h-10 w-40 items-center gap-1 rounded-3 bg-accent3-light pl-2 pr-3 lg:h-16 lg:w-48 lg:gap-2 lg:rounded-5 lg:px-4"
            disabled={isPending}
          >
            <Image className="size-6 flex-none lg:size-10" src={avatar || userImage} alt="" draggable="false" />
            <span className="text-px-12 font-bold text-black">{name || shortenAddress(address)}</span>
          </PopoverButton>
          <PopoverPanel
            className="mt-1 w-40 divide-y divide-dashed divide-black/50 rounded-5 bg-white px-4 shadow-y-4-10 lg:w-48"
            as="ul"
            transition
            anchor="bottom end"
          >
            <li className="w-full">
              <Href className={baseItemClassName} to="/profile">
                <Icon name="ui/user" className="size-5" />
                <Message value={messages.profile} />
              </Href>
            </li>
            <li className="w-full">
              <Href className={baseItemClassName} toTab="#">
                <Icon name="ui/circular-gear" className="size-5" />
                <Message value={messages.support} />
              </Href>
            </li>
            <li className="w-full">
              <ButtonBase
                className={clsx(baseItemClassName, 'text-[#ff3d3d]')}
                size={null}
                styling={null}
                onClick={() => disconnect()}
              >
                <Icon name="ui/exit" className="size-5" />
                <Message value={messages.disconnect} />
              </ButtonBase>
            </li>
          </PopoverPanel>
        </Popover>
      </div>
    </ConnectButtonWrapper>
  )
}

export default ProfileButton
