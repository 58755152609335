'use client'

import Header from 'compositions/Header/Header'
import Footer from 'compositions/Footer/Footer'
import GlobalModalsRegistrar from 'compositions/modals/GlobalModalsRegistrar/GlobalModalsRegistrar'
import { AuthProvider } from 'contexts'
import { usePathname } from 'next/navigation'
import FloatingBalancePill from 'compositions/FloatingBalancePill/FloatingBalancePill'

export default function PageLayout({ children }: any) {
  const pathname = usePathname()

  if (pathname === '/withdrawal-manager') {
    return <AuthProvider>{children}</AuthProvider>
  }

  return (
    <AuthProvider>
      <div className="relative flex min-h-dvh flex-col mb:pb-15">
        <Header />
        <div className="flex-1">{children}</div>
        <Footer />
      </div>
      <GlobalModalsRegistrar />
      <FloatingBalancePill />
    </AuthProvider>
  )
}
