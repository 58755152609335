'use client'

import { Icon, type IconName } from 'components/ui'
import messages from './messages'
import { Href } from 'components/navigation'
import { Message } from '@locmod/intl'

type Link = {
  toTab?: string
  icon: IconName
  title: Intl.Message
}

const links: Link[] = [
  {
    title: messages.docs,
    icon: 'ui/open-book',
    toTab: '#',
  },
  {
    title: messages.discord,
    icon: 'ui/discord',
    toTab: 'https://discord.gg/5etSxWBzVJ',
  },
  {
    title: messages.twitter,
    icon: 'ui/twitter',
    toTab: 'https://x.com/piggybankcorp',
  },
]

type Backer = { iconName: IconName; title: string; link?: string }
const backers: Backer[] = [
  {
    iconName: 'backers/yelay',
    title: 'Yelay',
  },
]

const year = new Date().getFullYear()

const Footer: React.FC = () => {
  return (
    <footer className="flex min-h-[172px] bg-[#190A0A] text-accent1-light">
      <div className="container mx-auto p-10">
        <div className="flex items-center justify-between gap-8 mb:flex-col">
          <div className="flex flex-wrap items-center gap-3 mb:justify-center">
            <Message value={messages.backers} className="block text-px-14 leading-140p mb:text-center" />
            <div className="flex gap-2 text-px-16 font-semibold leading-none text-accent1-light">
              {backers.map(({ iconName, title, link }) => {
                const content = (
                  <>
                    <Icon name={iconName} className="mr-1 size-6" />
                    <span>{title}</span>
                  </>
                )

                const itemClassName = 'inline-flex items-center'

                if (link) {
                  return (
                    <Href key={title} className={itemClassName} toTab={link}>
                      {content}
                    </Href>
                  )
                }

                return (
                  <div key={title} className={itemClassName}>
                    {content}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex gap-6 lg:-ml-28">
            {links.map(({ toTab, icon, title }) => (
              <Href key={toTab} className="flex gap-2" toTab={toTab}>
                <Icon className="size-full h-6 w-6" name={icon} />
                <Message value={title} className="font-semibold" />
              </Href>
            ))}
          </div>
          <div className="flex gap-2">
            <Icon name="ui/shield-with-checkmark" className="size-full h-6 w-6" />
            <Message value={messages.audited} />
          </div>
        </div>
        <p className="mt-10 text-center text-px-14 leading-140p">© PiggyBanks, {year}</p>
      </div>
    </footer>
  )
}

export default Footer
