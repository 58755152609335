import { Href, ActiveLink } from 'components/navigation'
import { Icon, type IconName } from 'components/ui'
import ProfileButton from './components/ProfileButton/ProfileButton'
import messages from './messages'

type Link = {
  to?: string
  toTab?: string
  title: Intl.Message
}

const links: Link[] = [
  {
    title: messages.earn,
    to: '/#earn',
  },
  {
    title: messages.boxes,
    to: '/#cases',
  },
  {
    title: messages.drops,
    to: '/profile',
  },
]

const socials: { toTab: string; icon: IconName }[] = [
  {
    icon: 'ui/discord',
    toTab: 'https://discord.gg/5etSxWBzVJ',
  },
  {
    icon: 'ui/twitter',
    toTab: 'https://x.com/piggybankcorp',
  },
]

const Header: React.FC = () => {
  return (
    <header className="flex h-16 items-center bg-accent1 lg:h-22" id="header">
      <div className="container mx-auto flex h-22 grid-cols-[1fr_auto_1fr] items-center justify-between gap-x-3 px-4 lg:grid lg:px-10">
        <div className="text-left">
          <Href to="/">
            <Icon name="ui/logo" className="h-10 w-auto lg:h-15" />
          </Href>
        </div>
        <div className="bottom-0 left-0 z-50 flex h-15 w-full items-center justify-center gap-10 bg-accent1 mb:fixed mb:px-4 lg:h-10">
          {links.map(({ to, toTab, title }) => (
            <ActiveLink
              key={title.en}
              className="text-center text-px-12 font-bold text-accent1-light mb:flex-1 lg:text-px-16"
              to={to}
              toTab={toTab}
              message={title}
            />
          ))}
          <div className="flex items-center gap-3 mb:hidden">
            {socials.map(({ toTab, icon }) => (
              <Href
                key={toTab}
                className="flex size-10 items-center justify-center rounded-lg bg-accent1-light/20 p-2 text-accent1-light"
                toTab={toTab}
              >
                <Icon className="size-full" name={icon} />
              </Href>
            ))}
          </div>
        </div>
        <div className="text-right">
          <ProfileButton />
        </div>
      </div>
    </header>
  )
}

export default Header
