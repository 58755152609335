'use client'

import dynamic, { type DynamicOptionsLoadingProps } from 'next/dynamic'
import { ModalsRenderer, registerModals } from '@locmod/modal'

import { Icon, PlainModal } from 'components/ui'
import SiweModal from 'compositions/modals/SiweModal/SiweModal'
import ErrorModal from 'compositions/modals/ErrorModal/ErrorModal'
import CommonModal from 'compositions/modals/CommonModal/CommonModal'
import SuccessModal from 'compositions/modals/SuccessModal/SuccessModal'

const dumbCloseModal = () => {}

export const ModalSkeleton = (loadingProps?: DynamicOptionsLoadingProps) => {
  const { isLoading = true } = loadingProps || {}

  if (!isLoading) {
    return null
  }

  return (
    <PlainModal className="relative aspect-square" closeModal={dumbCloseModal} withCloseButton={false}>
      <div className="absolute inset-0 flex items-center justify-center">
        <Icon name="ui/loader" className="size-16" />
      </div>
    </PlainModal>
  )
}

const loading = ModalSkeleton

const registry = {
  SiweModal,
  CommonModal,
  ErrorModal,
  SuccessModal,
  WaitingModal: dynamic(() => import('compositions/modals/WaitingModal/WaitingModal'), {
    loading,
  }),
  SwitchChainModal: dynamic(() => import('compositions/modals/SwitchChainModal/SwitchChainModal'), {
    loading,
  }),
  DepositModal: dynamic(() => import('compositions/modals/DepositModal/DepositModal'), {
    loading,
  }),
  WithdrawVaultModal: dynamic(() => import('compositions/modals/WithdrawVaultModal/WithdrawVaultModal'), {
    loading,
  }),
  LootboxModal: dynamic(() => import('compositions/modals/LootboxModal/LootboxModal'), {
    loading,
  }),
  WithdrawRewardsModal: dynamic(() => import('compositions/modals/WithdrawRewardsModal/WithdrawRewardsModal'), {
    loading,
  }),
  ExchangeForCreditsModal: dynamic(
    () => import('compositions/modals/ExchangeForCreditsModal/ExchangeForCreditsModal'),
    {
      loading,
    },
  ),
}

registerModals(registry)

const GlobalModalsRegistrar: React.FC = () => <ModalsRenderer />

export default GlobalModalsRegistrar
