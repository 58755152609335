import { useEnsName, useEnsAvatar } from 'wagmi'
import { normalize } from 'viem/ens'
import { useAuthContext } from 'contexts'

export default function useUserEns() {
  const { address } = useAuthContext()
  const { data: ensName, isFetching: isFetchingName } = useEnsName({ address })
  const { data: ensAvatar, isFetching: isFetchingAvatar } = useEnsAvatar({
    name: ensName ? normalize(ensName) : undefined,
  })

  const isFetching = isFetchingName || isFetchingAvatar

  return {
    name: ensName,
    avatar: ensAvatar,
    isFetching,
  }
}
