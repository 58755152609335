'use client'

import type { ReactNode } from 'react'
import { PlainModal, type IconName, Icon } from 'components/ui'
import { Message } from '@locmod/intl'
import type { ModalComponent } from '@locmod/modal'
import { Button, type ButtonProps, buttonMessages } from 'components/inputs'

export type CommonModalProps = {
  title: Intl.Message | string
  iconName?: IconName
  subTitle?: Intl.Message | string | null
  footerText?: Intl.Message | string
  children?: ReactNode
  buttonProps?: Partial<ButtonProps> | null
  withCloseButton?: boolean
  secondButtonProps?: Partial<ButtonProps> & { title: ButtonProps['title'] }
}

const CommonModal: ModalComponent<CommonModalProps> = (props) => {
  const {
    children,
    title,
    subTitle,
    footerText,
    iconName,
    buttonProps,
    secondButtonProps,
    withCloseButton = true,
    closeModal,
  } = props

  const handleClick: ButtonProps['onClick'] = (event) => {
    closeModal()

    if (buttonProps?.onClick) {
      buttonProps.onClick(event)
    }
  }

  const handleSecondClick: ButtonProps['onClick'] = (event) => {
    closeModal()

    if (secondButtonProps?.onClick) {
      secondButtonProps.onClick(event)
    }
  }

  return (
    <PlainModal
      className="flex flex-col text-center"
      withCloseButton={withCloseButton}
      closeModal={closeModal}
    >
      <Message
        className="text-center text-px-24 font-black leading-150p text-black"
        tag="h2"
        value={title}
        html
      />
      {Boolean(subTitle) && (
        <Message
          className="text-px-14 font-medium leading-150p text-black/80"
          value={subTitle!}
          tag="p"
          html
        />
      )}
      {Boolean(iconName) && <Icon className="mx-auto mt-6 size-15" name={iconName!} />}
      {Boolean(children) && (
        <div className="mt-6 text-px-14 font-medium leading-150p text-black/80">{children}</div>
      )}
      <div className="mt-6 grid grid-cols-1 gap-4 empty:hidden lg:inline-flex">
        {buttonProps !== null && (
          <Button
            title={buttonProps?.title || buttonMessages.ok}
            size="big"
            styling="main"
            {...buttonProps}
            // handleClick must be after to close modal on click
            onClick={handleClick}
          />
        )}
        {Boolean(secondButtonProps) && (
          <Button size="big" styling="third" {...secondButtonProps!} onClick={handleSecondClick} />
        )}
      </div>
      {Boolean(footerText) && (
        <Message className="mt-6 text-px-13 leading-150p text-grey-800" value={footerText!} tag="p" html />
      )}
    </PlainModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ CommonModal: typeof CommonModal }> {}
}

export default CommonModal
