export default {
  earn: {
    en: 'Earn',
  },
  boxes: {
    en: 'Lootboxes',
  },
  drops: {
    en: 'My drops',
  },
}
