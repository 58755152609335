'use client'

import { useProfileMe, useClientOnly } from 'hooks'
import { Icon } from 'components/ui'
import { toLocaleString } from 'helpers'
import { useInView } from 'framer-motion'
import clsx from 'clsx'
import { useModalOpenState } from '@locmod/modal'
import { useRef } from 'react'

const Content = ({ balance, isFetching }: { balance: number | undefined; isFetching: boolean }) => {
  // const ref = useRef<Element>(document.getElementById('header'))
  // const isHeaderVisible = useInView(ref)

  const isModalOpen = useModalOpenState('LootboxModal')
  // const isVisible = isModalOpen || !isHeaderVisible
  const isVisible = isModalOpen

  return (
    <div
      className={clsx(
        'container fixed left-0 right-0 top-0 mx-auto flex items-center justify-center px-10 pt-4 lg:justify-end',
        'z-[70] transition-transform duration-300',
        !isVisible && '-translate-y-24',
      )}
    >
      <div
        className={clsx(
          'flex items-center px-4 py-3 text-px-12 font-bold text-black',
          'rounded-5 bg-[#F5F3E9] shadow-y-4-10',
        )}
      >
        <Icon name="ui/coin" className="mr-1 size-6 flex-none" />
        {isFetching && !balance ? (
          <Icon name="ui/loading" className="h-[1em]" />
        ) : (
          toLocaleString(balance || 0, { digits: 0 })
        )}
        <span className="sr-only"> Credits on your balance</span>
      </div>
    </div>
  )
}

export default function FloatingBalancePill() {
  const { data, isFetching, isFetched } = useProfileMe()

  const isClient = useClientOnly()

  if ((!isFetched && !isFetching) || !isClient) {
    return null
  }

  return <Content balance={data?.balance} isFetching={isFetching} />
}
